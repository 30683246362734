<template>
  <div
    class="v-application v-application--is-ltr theme--light menuable__content__active h-100"
  >
    <!--begin::SubIn-->
    <b-card class="h-100">
      <div class="row justify-content-between align-self-center mb-3">
        <div>
          <h3 class="text-primary mb-1">TDP Gears</h3>
          <span class="text-muted"
            >Showing {{ parseInt(this.params.offset) + 1 }} to
            {{ parseInt(this.params.offset) + parseInt(params.limit) }} of
            {{ totalCount }} entries</span
          >
        </div>
        <div class="row no-gutters" style="gap: 8px">
          <b-form-group class="mb-0">
            <div class="input-icon">
              <input
                v-model="params.query"
                type="text"
                class="form-control"
                placeholder="Search..."
              />
              <span><i class="flaticon2-search-1 icon-md"></i></span>
            </div>
          </b-form-group>
          <b-form-group class="mb-0">
            <b-form-select
              v-model="params.order"
              :options="filterSort"
            ></b-form-select>
          </b-form-group>
          <b-button variant="primary" style="height: 38px" v-b-modal.modal-gears
            >Tambah Gear</b-button
          >
        </div>
      </div>
      <div
        class="h-100 d-flex pb-8"
        style="flex-direction: column; justify-content: space-between"
      >
        <div class="table-responsive">
          <b-table
            hover
            :items="tableData"
            :fields="fields"
            :per-page="params.limit"
            :current-page="refCurrentPage"
            :busy="isLoading"
            show-empty
          >
            <template #cell(images)="row">
              <img
                :src="row.item?.images?.length ? row.item.images[0].url : ''"
                width="50"
                height="50"
              />
            </template>
            <template #cell(sizes)="row">
              <b-badge
                class="mr-1"
                variant="secondary"
                v-for="list in row.item.sizes"
                :key="list.id"
                >{{ list.size }}</b-badge
              >
            </template>
            <template #cell(action)="row">
              <b-button
                variant="success"
                class="py-1 px-2 font-weight-bold"
                id="detail"
                title="proses"
                @click="handleDetail(row.item)"
              >
                <i class="menu-icon flaticon-eye pr-0"></i>
              </b-button>
              <b-button
                variant="primary"
                class="py-1 px-2 ml-2 font-weight-bold"
                id="detail"
                title="edit"
                @click="handleEdit(row.item)"
              >
                <i class="menu-icon flaticon2-edit pr-0"></i>
              </b-button>
              <b-button
                variant="danger"
                class="py-1 px-2 ml-2 font-weight-bold"
                id="detail"
                title="proses"
                @click="handleDelete(row.item)"
              >
                <i class="menu-icon flaticon2-trash pr-0"></i>
              </b-button>
            </template>
            <template #table-busy>
              <div class="text-center text-muted my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </div>
        <div class="row justify-content-between">
          <div class="col-md-1">
            <b-form-group class="mb-0">
              <b-form-select
                id="per-page-select"
                v-model="params.limit"
                :options="pageOptions"
                size="sm"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-pagination
              v-model="refCurrentPage"
              :total-rows="totalCount"
              :per-page="params.limit"
              size="md"
              align="fill"
              class="ml-auto"
              style="padding: 0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </b-card>
    <b-modal
      id="modal-gears"
      size="lg"
      :title="Object.keys(selectedList).length ? 'Edit Gears' : 'Tambah Gears'"
      @hide="resetModal"
    >
      <form ref="form">
        <b-form-group
          id="input-group-2"
          label="Nama Gears*"
          label-for="input-2"
        >
          <b-form-input
            id="input-2"
            v-model="form.name"
            required
            placeholder="Masukan nama disini"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-2"
          label="Size Gears*"
          label-for="input-2"
        >
          <multiselect
            :options="options"
            v-model="form.sizes"
            tag-placeholder="Masukan size disini"
            placeholder="Masukan size disini"
            :multiple="true"
            :taggable="true"
            @tag="addTag"
            label="size"
            :showNoOptions="false"
          >
            <template slot="tag" slot-scope="{ option }">
              <span class="custom__tag">
                <span>{{ option.size }}</span>
                <span
                  class="custom__remove"
                  v-if="form.sizes.length > 1"
                  @click="onRemoveSize(option)"
                  >x</span
                >
              </span>
            </template>
          </multiselect>
        </b-form-group>
        <b-form-group
          id="input-group-2"
          label="Harga Gears*"
          label-for="input-2"
        >
          <div class="input-icon">
            <input
              type="text"
              class="form-control"
              placeholder="Masukan harga disini"
              v-model="form.price"
              @input="formatNumber('price')"
            />
            <span>Rp.</span>
          </div>
        </b-form-group>
        <b-form-group label="Upload Gambar*" label-for="input-2">
          <DropFile
            @imagesChange="imagesChange"
            @imagesRemove="removeImages"
            :listFile="form.images"
          ></DropFile>
        </b-form-group>
      </form>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="md"
            class="mr-3"
            @click="
              modalStatus === 'Edit' ? handleEditSubmit() : handleSubmit()
            "
          >
            {{ modalStatus === "Edit" ? "Update" : "Simpan" }}
          </b-button>
          <b-button variant="secondary" size="md" @click="hideModal">
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      hide-footer
      id="modal-view"
      size="md"
      title="View Gears"
      @hide="resetModal"
    >
      <div v-show="Object.keys(selectedList).length">
        <carousel :perPage="1">
          <slide
            v-for="(image, index) in selectedList.images"
            :key="index"
            style="height: 455px"
          >
            <img :src="image.url" alt="slide image" width="100%" />
          </slide>
        </carousel>
        <h3 class="mt-8 font-weight-bold">{{ selectedList.name }}</h3>
        <h1 class="font-weight-bold text-primary">
          {{ formatRupiah(selectedList.price) }}
        </h1>
        <h4 class="mt-8">Size Gears</h4>
        <div>
          <b-badge
            class="mr-1"
            variant="secondary"
            v-for="list in selectedList.sizes"
            :key="list.id"
            >{{ list.size }}</b-badge
          >
        </div>
      </div>
    </b-modal>
    <!--end::SubIn-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Multiselect from "vue-multiselect";
import { formatRupiah } from "@/utils/helper";
import _ from "lodash";
import DropFile from "@/components/DropFile.vue";

export default {
  name: "GearList",
  components: {
    Multiselect,
    DropFile,
  },
  data() {
    const { query, sort, order, limit, offset } = this.$route.query;
    return {
      // Table Req
      selected: null,
      isLoading: false,
      currentPage: 1,
      pageOptions: [10, 15, 100],
      filterSort: [
        { value: "desc", text: "Terbaru" },
        { value: "asc", text: "Terlama" },
      ],
      fields: [
        {
          key: "images",
          label: "Gambar",
          align: "left",
          sortBy: "",
          width: 50,
          // sortable: true,
        },
        {
          key: "id",
          label: "ID",
          align: "left",
          sortBy: "",
          sortByFormatted: true,
          filterByFormatted: true,
          width: 100,
          // sortable: true,
        },
        {
          key: "name",
          label: "Nama Gears",
          align: "left",
          sortBy: "",
          // sortable: true,
        },
        {
          key: "sizes",
          label: "Atribut",
          align: "left",
          sortBy: "",
          // sortable: true,
        },
        {
          key: "action",
          align: "center",
          width: 100,
        },
      ],
      form: {
        name: "",
        sizes: [],
        price: 0,
        images: [],
      },
      sortBy: "",
      sortDesc: true,
      sortDirection: "asc",
      tableData: [],
      totalCount: 0,
      params: {
        query: query ?? null,
        sort: sort ?? "created_at",
        order: order ?? "asc",
        limit: limit ?? 10,
        offset: offset ?? 0,
      },
      //-----//
      options: [],
      selectedList: {},
      tempCompareData: {},
      modalStatus: "",
      initialFiles: [],
    };
  },
  computed: {
    refCurrentPage: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.params.offset = (value - 1) * this.params.limit;
      },
    },
    paramsComp() {
      return JSON.parse(JSON.stringify(this.params));
    },
  },
  mounted() {
    this.getData();
    // this.totalRows = this.tableData.length;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "TDP Gears" },
    ]);
  },
  methods: {
    formatRupiah,
    async imagesChange(item) {
      this.form.images = item;
      if (Object.keys(this.selectedList).length) {
        const imageUpload = item.filter(
          (item) => item.name.substring(0, 4) !== "http"
        );
        if (imageUpload.length) {
          imageUpload.forEach(async (image) => {
            let formData = new FormData();
            formData.append("gear_id", this.selectedList.id);
            formData.append("image", image);
            await this.$axios
              .post(
                `${process.env.VUE_APP_BASE_API_DADA}/v1/cms/gear/image`,
                formData
              )
              .then(() => {
                this.$bvToast.toast("Berhasil menambahkan image!", {
                  title: "Success",
                  variant: "success",
                  solid: true,
                });
                this.getDetailData();
              })
              .catch(({ response }) => {
                this.$bvToast.toast(
                  response?.data?.message ??
                    `Terjadi Kesalahan dalam upload ${image.name}`,
                  {
                    title: "Error",
                    variant: "danger",
                    solid: true,
                  }
                );
              });
          });
        }
      }
    },
    navigateUrl() {
      const { query, sort, order, limit, offset } = this.params;
      const routeParams = { query, sort, order, limit, offset };
      if (JSON.stringify(this.$route.query) !== JSON.stringify(routeParams)) {
        this.$router.push({
          path: `/gears/list`,
          query: routeParams,
        });
      }
    },
    formatNumber(model) {
      let value = this.form[model].replace(/\D/g, "");
      value = new Intl.NumberFormat("id-ID").format(value);
      this.form[model] = value;
    },
    handleDelete(item) {
      this.$swal({
        title: "Hapus gear ini ?",
        showCancelButton: true,
        showDenyButton: true,
        showConfirmButton: false,
        denyButtonText: `Hapus`,
        heightAuto: false,
      }).then(async (result) => {
        if (result.isDenied) {
          await this.$axios
            .delete(
              `${process.env.VUE_APP_BASE_API_DADA}/v1/cms/gear/size/${item.id}`
            )
            .then(() => {
              this.$swal({
                text: "Berhasil menghapus data!",
                icon: "success",
                heightAuto: false,
              });
              this.getData();
            })
            .catch(({ response }) => {
              this.$bvToast.toast(response.data.message, {
                title: "Error",
                variant: "danger",
                solid: true,
              });
            });
        }
      });
    },
    getData: _.debounce(async function () {
      try {
        this.isLoading = true;
        this.navigateUrl();
        await this.$axios
          .get(`${process.env.VUE_APP_BASE_API_DADA}/v1/cms/gears`, {
            params: this.params,
          })
          .then(({ data }) => {
            this.tableData = data.list;
            this.totalCount = data.total;
          });
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message, {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoading = false;
      }
    }, 500),
    async getDetailData() {
      try {
        await this.$axios
          .get(
            `${process.env.VUE_APP_BASE_API_DADA}/v1/cms/gear/${this.selectedList.id}`
          )
          .then(({ data }) => {
            const dataSelected = JSON.parse(JSON.stringify(data));
            this.selectedList = dataSelected;
            this.form = {
              name: dataSelected.name,
              sizes: dataSelected.sizes,
              price: String(dataSelected.price),
              images: [],
            };
            this.formatNumber("price");
            if (data.images.length > 0) {
              this.$nextTick(() => {
                data.images.forEach((item) => {
                  const file = new File([], item.url, { type: "image/png" });
                  this.form.images.push(file);
                });
              });
            }
          });
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message, {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
    onFiltered(filteredItems) {
      this.totalCount = filteredItems.length;
      this.currentPage = 1;
    },
    handleDetail(selectedList) {
      this.modalStatus = "View";
      this.selectedList = selectedList;
      this.$bvModal.show("modal-view");
    },
    resetModal() {
      if (
        Object.keys(this.selectedList).length &&
        this.modalStatus !== "View"
      ) {
        if (!_.isEqual(this.tempCompareData, this.form)) this.getData();
      }
      this.form = {
        name: "",
        sizes: [],
        price: 0,
        images: [],
      };
      this.selectedList = {};
      this.modalStatus = "";
    },
    async handleSubmit() {
      try {
        let formData = new FormData();
        formData.append("name", this.form.name);
        formData.append("description", "Test sementara asu");
        formData.append("price", parseInt(this.form.price.replace(/\D/g, "")));
        this.form.images.forEach((item) => {
          formData.append("images[]", item);
        });
        this.form.sizes.forEach((item) => {
          formData.append("sizes[]", item.size);
        });
        formData.append("admin_fee", 0);
        await this.$axios.post(
          `${process.env.VUE_APP_BASE_API_DADA}/v1/cms/gear`,
          formData
        );
        this.$swal({
          text: "Berhasil menambahkan gear!",
          icon: "success",
          heightAuto: false,
        });
        this.hideModal();
        this.getData();
      } catch ({ response }) {
        this.$bvToast.toast(response?.data?.message ?? "Terjadi Kesalahan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
    async handleEditSubmit() {
      try {
        const payload = {
          name: this.form.name,
          description: "asu",
          price: parseInt(this.form.price.replace(/\D/g, "")),
        };
        await this.$axios.put(
          `${process.env.VUE_APP_BASE_API_DADA}/v1/cms/gear/${this.selectedList.id}`,
          payload
        );
        this.hideModal();
        this.$swal({
          text: "Berhasil mengedit gear!",
          icon: "success",
          heightAuto: false,
        });
      } catch ({ response }) {
        this.$bvToast.toast(response?.data?.message ?? "Terjadi Kesalahan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
    async addTag(newTag) {
      const index = this.form.sizes.findIndex((item) => item.size === newTag);
      if (index === -1) this.form.sizes.push({ size: newTag });
      else return;
      if (Object.keys(this.selectedList).length) {
        await this.$axios.post(
          `${process.env.VUE_APP_BASE_API_DADA}/v1/cms/gear/size`,
          {
            gear_id: this.selectedList.id,
            size: newTag,
          }
        );
        this.getDetailData();
      }
    },
    async onRemoveSize(removeTag) {
      if (removeTag instanceof Object) {
        const index = this.form.sizes.findIndex(
          (item) => item.size === removeTag.size
        );
        if (index !== -1) this.form.sizes.splice(index, 1);
        else return;
        if (Object.keys(this.selectedList).length) {
          await this.$axios.delete(
            `${process.env.VUE_APP_BASE_API_DADA}/v1/cms/gear/size/${removeTag.id}`
          );
          this.getDetailData();
        }
      }
    },
    handleEdit(selectedList) {
      this.$bvModal.show("modal-gears");
      this.modalStatus = "Edit";
      const dataSelected = JSON.parse(JSON.stringify(selectedList));
      this.selectedList = dataSelected;
      this.form = {
        name: dataSelected.name,
        sizes: dataSelected.sizes,
        price: String(dataSelected.price),
        images: [],
      };
      this.formatNumber("price");
      if (selectedList.images.length > 0) {
        this.$nextTick(() => {
          for (const item of selectedList.images) {
            const file = new File([], item.url, { type: "image/png" });
            this.form.images.push(file);
          }
          this.tempCompareData = this.form;
        });
      }
    },
    hideModal() {
      this.$bvModal.hide("modal-gears");
    },
    async removeImages(file, index) {
      const imagesFind = this.selectedList.images.find(
        (item) => item.url === file.name
      );
      if (Object.keys(imagesFind ?? {}).length) {
        this.$swal({
          title: "Hapus Image ini ?",
          showCancelButton: true,
          showDenyButton: true,
          showConfirmButton: false,
          denyButtonText: `Hapus`,
          heightAuto: false,
        }).then(async (result) => {
          if (result.isDenied) {
            await this.$axios
              .delete(
                `${process.env.VUE_APP_BASE_API_DADA}/v1/cms/gear/image/${imagesFind.id}`
              )
              .then(() => {
                this.$bvToast.toast("Berhasil menghapus image!", {
                  title: "Success",
                  variant: "success",
                  solid: true,
                });
                this.getDetailData();
              })
              .catch(({ response }) => {
                this.$bvToast.toast(
                  response?.data?.message ?? "Terjadi Kesalahan",
                  {
                    title: "Error",
                    variant: "danger",
                    solid: true,
                  }
                );
              });
            this.form.images.splice(index, 1);
            return true;
          } else return false;
        });
      } else return false;
    },
  },
  watch: {
    $route(newValue, oldValue) {
      if (newValue.path !== oldValue.path) {
        this.params = {
          query: null,
          sort: "created_at",
          order: "desc",
          limit: 10,
          offset: 0,
        };
        this.getData();
      }
    },
    paramsComp: {
      deep: true,
      handler: _.debounce(function (newValue, oldValue) {
        if (newValue.query !== oldValue.query) {
          this.params.offset = 0;
        }
        this.getData();
      }, 400),
    },
  },
};
</script>

<style>
.multiselect__tag-icon {
  line-height: 16px !important;
}
.multiselect__tags {
  padding: 8px 40px 8px 8px !important;
}
.multiselect__tags-wrap {
  display: flex !important;
  gap: 4px;
  flex-flow: wrap;
}
</style>
<style lang="scss" scoped>
.sub-status {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  padding: 4px 8px;
  color: #89909a;
  width: fit-content;
  border-radius: 8px;
  font-weight: 500;
  gap: 8px;
  i {
    color: #89909a;
  }
}

.subtitle {
  color: #314b5f;
}
.custom__tag {
  display: flex;
  justify-content: center;
  border-radius: 24px;
  background: #d4dfef;
  padding: 4px 8px;
  width: fit-content;
  color: #4b4b4b;
  gap: 8px;
  font-weight: 500;
}
.custom__remove {
  cursor: pointer;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
